export class configAPI{

    constructor() {
      this.API = '';
      this.signUp = '';
      this.app = '';
    }

    init (url){
        if (url.indexOf('yon-alpha-signup-dev.web.app')  != -1 || url.indexOf('localhost:8080')  != -1 || url.indexOf('localhost:8081')  != -1){

            this.API = 'https://alpha-api-dev.yesorno.bet';
            this.signUp = 'https://yon-alpha-signup-dev.web.app';
            this.app = 'https://yon-alpha-app-dev.web.app';

        }

        if (url.indexOf('yon-alpha-signup-preprod.web.app')  != -1){

            this.API = 'https://alpha-api-preprod.yesorno.bet';
            this.signUp = 'https://yon-alpha-signup-preprod.web.app';
            this.app = 'https://yon-alpha-app-preprod.web.app';

        }

        if (url.indexOf('yon-alpha-signup.web.app')  != -1 || url.indexOf('alpha-signup.yesorno.bet')  != -1 ){

            this.API = 'https://alpha-api.yesorno.bet';
            this.signUp = 'https://alpha-signup.yesorno.bet';
            this.app = 'https://alpha-app.yesorno.bet';

        }
    }

    getAPI(){
        return (this.API);
    }

    getsignUp(){
        return (this.signUp);
    }

    getapp(){
        return (this.app);
    }

}