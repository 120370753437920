import { validationMixin } from 'vuelidate';
import { configAPI } from '../../config/config';

var ConfigAPI = new configAPI();


//if (document.getElementById("timer").innerHTML != null) {
/*	var deadline = new Date("Apr 5, 2022 18:00:00").getTime();
	var x = setInterval(function () {
		var now = new Date().getTime();
		var t = deadline - now;
		var days = Math.floor(t / (1000 * 60 * 60 * 24));
		var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((t % (1000 * 60)) / 1000);
		document.getElementById("timer").innerHTML = days + " jours "
			+ hours + " heures " + minutes + " minutes " + seconds + "s ";
		if (t < 0) {
			clearInterval(x);
			document.getElementById("timer").innerHTML = "L'alpha est ouverte";
		}
	}, 1000);
//}*/


/*document.getElementById("btnRedirect").addEventListener("click", window.location.href = ConfigAPI.getapp(), false);

function redirectToApp() {
	
}*/

export default {
	name: 'Login',
	mixins: [validationMixin],
	data() {
		return {
			referralLink: '',
			referral: '',
			address: '',
			addressApp: ''
		}
	},
	validations: {

	},
	methods: {
		redirectToApp() {
			window.location.href = this.addressApp;
		}
	},
	created() {

		ConfigAPI.init(window.location.origin);

		console.log(this.$route.query.referral);
		if (this.$route.query.referral != undefined) {
			this.referral = this.$route.query.referral;
			this.referralLink = ConfigAPI.getsignUp() + "/?referral=" + this.$route.query.referral;
		}
		this.addressApp = ConfigAPI.getapp();

		if (this.$route.query.address != undefined) {
			this.address = this.$route.query.address;
		}

		
	}
}