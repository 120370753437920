import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
const axios = require('axios');
import Web3 from 'web3';
const sigUtil = require('eth-sig-util');
const ethUtil = require('ethereumjs-util');
import { configAPI }  from '../../config/config';

var ConfigAPI = new configAPI();

export default {
	name: 'Login',
	mixins: [validationMixin],
	data() {
		return {
			adresse: '',
			nickname: '',
			username: '',
			referral: '',
			email: '',
			Bcvalid: false,
			newsletter: true,
			showerror: false,
			invalid: false,
			valid: false,
			offi: false,
			type: this.$route.query.type,
			errorMessage: '',
			messageSigned: '',
			signature: '',
			Metamask: true
		}
	},
	validations: {
		referral: {
		},
		username: {
			required,
		},
		email: {
			required,
			email,
		}
	},
	methods: {
		redirectto() { },
		async send(e) {
			e.preventDefault();

			if (this.$v.$invalid) {
				this.showerror = true;
				this.invalid = false;
				this.valid = false;
				this.Bcvalid = false;
			}

			else {
				this.errorMessage = '';
				this.invalid = false;
				this.Bcvalid = false;

				if (typeof window.ethereum === 'undefined') {
					this.showerror = false;
					this.invalid = true;
					this.valid = false;
					this.Bcvalid = false;
					this.errorMessage = 'MetaMask is not installed!';
					console.error('There was an error!', 'MetaMask is not installed!');
					console.log('MetaMask is not installed!');
				}

				else {

					try {
						var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
					}
					catch (error) {
						this.errorMessage = "Error with Metamask login";
						this.showerror = false;
						this.invalid = true;
						this.Bcvalid = false;
						this.valid = false;
						console.log("Error with Metamask login");
						return console.error('ERROR', "Error with Metamask login");
					};

					if (accounts == null) {
						this.errorMessage = "Error with Metamask login";
						this.showerror = false;
						this.invalid = true;
						this.Bcvalid = false;
						this.valid = false;
						console.log("Error with Metamask login");
						return console.error('ERROR', "Error with Metamask login");
					}

					console.log(accounts);
					const account = accounts[0];
					const salt = 'Account creation';// + accounts[0];
					console.log(account);
					this.adresse = account;



					const options = {
						headers: { "content-type": "application/json" }
					}

					let isAccountExist = false;

					let urlcheckUserExist = ConfigAPI.getAPI() + '/users/' + this.adresse;
					try {
						await axios.get(urlcheckUserExist, options)

						isAccountExist = true;
						window.location.href = ConfigAPI.getapp()
						return console.log('Success Redirect to Alpha url');
					}
					catch (error) {
						console.log(error);
					};
					console.log(isAccountExist);







					if (isAccountExist == false) {
						var web3 = new Web3(Web3.givenProvider);

						const msgParams = JSON.stringify({
							domain: { chainId: '0x61', name: 'YON Alpha Admission' },
							message: { contents: 'Account creation' },
							primaryType: 'ChallengeReport',
							types: {
								EIP712Domain: [
									{ name: 'chainId', type: 'uint256' },
									{ name: 'name', type: 'string' },
								],
								ChallengeReport: [{ name: 'contents', type: 'string' }],
							},
						});

						try {
							await ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: '0x61',
										chainName: "YON Testnet",
										nativeCurrency: {
											name: "BNB",
											symbol: "BNB",
											decimals: 18
										},
										rpcUrls: [
											"https://nd-454-965-418.p2pify.com/62a6e9f2a78711639961d223ad9bd732"
										],
										blockExplorerUrls: ["https://testnet.bscscan.com"],
										iconUrls: [
											"https://storage.googleapis.com/yon-alpha-public/app/yon_token.png"
										]
									}],
							});
						} catch (addError) {
							this.showerror = false;
							this.invalid = false;
							this.valid = false;
							this.Bcvalid = true;
							console.log("Error with Metamask changing blockchain");
							return console.dir(addError);
						}
						
						var from = account;

						var params = [from, msgParams];

						var method = 'eth_signTypedData_v4';
						var result = await web3.currentProvider.sendAsync(
							{
								method,
								params,
								from,
							},
							(err, result) => {
								if (err) {
									this.errorMessage = "Error with Metamask Signature";
									this.showerror = false;
									this.invalid = true;
									this.valid = false;
									this.Bcvalid = false;
									console.log("Error with Metamask Signature");
									return console.dir(err);
								}
								if (result.error) {
									this.errorMessage = "Error with Metamask Signature";
									this.showerror = false;
									this.invalid = true;
									this.valid = false;
									this.Bcvalid = false;
									console.log("Error with Metamask Signature");
									return console.error('ERROR', result);
								}
								console.log('TYPED SIGNED:' + result.result);

								this.signature = result.result;


								const data = { address: this.adresse, signature: this.signature, referralCode: this.referral, email: this.email, username: this.username, newsletter: this.newsletter };
								console.log(data);
								const options = {
									headers: { "content-type": "application/json" }
								}

								let urlSignUP = ConfigAPI.getAPI() + '/users/signup'
								axios.post(urlSignUP, data, options)
									.then(response => {

										this.showerror = false;
										this.invalid = false;
										this.valid = true;
										this.Bcvalid = false;
										console.log(response);
										let urlBurn = '/Success?referral=' + response.data.userReferral + "&address=" + this.adresse;
										console.log(urlBurn);
										this.$router.push(urlBurn);

									})
									.catch(error => {
										console.log(error);
										this.errorMessage = error.response.data.message;
										this.showerror = false;
										this.invalid = true;
										this.valid = false;
										this.Bcvalid = false;
										console.log(error.response.data.message);
									});
								return console.log('Success', result);

							}
						)
					}
				}
			}
		}
	},
	created() {

		console.log(window.location.origin);
		ConfigAPI.init(window.location.origin);
		console.log('url API', ConfigAPI.getAPI());

		if (typeof window.ethereum === 'undefined') {
			this.showerror = false;
			this.invalid = true;
			this.valid = false;
			this.Bcvalid = false;
			this.Metamask = false;
			this.errorMessage = 'MetaMask is not installed!';
			console.error('There was an error!', 'MetaMask is not installed!');
			console.log('MetaMask is not installed!');
		}

		if (this.$route.query.referral != undefined)
			this.referral = this.$route.query.referral;


	}


}