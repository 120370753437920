import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import firebase from "firebase/compat/app"
import db from '../components/firebaseInit.js';

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {

	if (to.path != '/' && to.path != '/Success'){
		next({
			path: '/',
			query: {
				redirect: to.fullPath
			}
		});
	} else {
		next();
	}
})

export default router